const financialAdvisorText =[
  {topic: "income", condition: "level1", text: "Your salary is below average.  Seriously consider a new job.  " +
      "In addition, you should find ways to earn additional money " +
      "such as  working a side-hustle." },
  {topic:  "income", condition: "level2", text: "You make a good salary but you may want to consider a side hustle " +
      "to help with unexpected expenses or special vacations.  "},
  {topic: "income", condition: "level3", text: "You have a good job and your salary is above average." },
  {topic: "income", condition: "otherIncomeSources", text: "In this game you can increase your salary by earning some " +
      "badges or completing some of the extra credit activities."},
  {topic: "income", condition: "pendingJobMoreSalary", text: "It looks like you are currently in school " +
      "to earn a degree so that you can become a ||jobName||.  That is great!  When you get the degree and the new job, " +
      "your salary will increase."},
  {topic: "income", condition: "pendingJobLessSalary", text: "It looks like you are currently in school " +
      "to earn a degree to become a ||jobName||.  It is great that you are in school, but when you get the degree and " +
      "the new job, your salary will be less than it is now."},
  {topic: "expenses", condition: "rentOver30", text: "Your monthly rent is more than 30 percent of your " +
      "takehome pay (salary minus taxes). You may want to consider finding a new place to live that has " +
      "a lower monthly rent amount. "  },
  {topic: "expenses", condition: "roommate", text: "....OR.... You could consider getting a roommate to " +
      "help pay the rent."},
  {topic: "expenses", condition: "bedrooms", text: "Of course, if you are going to get a roommate, you'll need " +
      "to find a place that has more than 1 bedroom."}, 
  {topic: "expenses", condition: "miscExpLvl1", text: "You are spending too much on basic items such as food " +
      "clothing, and such.  You should consider eating at home more often, shopping at less expensive stores."},
  {topic: "expenses", condition: "miscExpLvl2", text: "You are spending a little too much on basic items such as food, " +
      "clothing, and such.  You could consider eating at home more often, and shopping at less expensive stores. "  +
      "Also, you should really consider using coupons!"},
  {topic: "expenses", condition: "miscExpLvl3", text: "You are doing a good job of managing the amount you spend " +
      "monthly on food and clothing."},
  {topic: "expenses", condition: "tooMuchCharity", text: "I see you are giving ||charityAmt|| to charities " +
      "each month.  While this is very nice of you and I definitely think you should gift when you can " +
      "you should probably reduce your charity contribution at this point. "},
  {topic: "expenses", condition: "considerCharity", text: "I see you are giving ||charityAmt|| to charities " +
      "each month.  It appears that your net income is more than $1000 so you might consider increasing your " +
      "charitable donations when you get a chance."},
  {topic: "expenses", condition: "sellHorse", text: "Your monthly net income is less than $500.  You should definitely "  +
      "sell your horse. "},
  {topic: "expenses", condition: "sellBoat", text: "It would be a good idea if you sell your boat.  The loan and " +
      "ongoing maintenance are taking a bite out of your net income."},
  {topic: "expenses", condition: "default", text: "I've reviewed all of your expenses.  It seems that you " +
      "are doing a good job of managing your expenses so they are not out of control at this time."  },
  {topic: "loans", condition: "mortgageTooHigh", text: "A house is a great investment, however, your monthly " +
      "mortgage payment is more than 30 percent of your " +
      "takehome pay (salary minus taxes). You may want to consider finding a new place to live that will have " +
      "a lower monthly amount. "  },
  {topic: "loans", condition: "mortgageWithRoommate", text: "Your mortgage amount is more than 30% of your " +
      "take-home pay (salary minus taxes) but, it looks like you have a roommate.  That is smart because it will " +
      "help you afford the monthly mortgage amount. "},
  {topic: "loans", condition: "sportsCar", text: "I see you own a sports car.  I know you probably really like " +
      "it... but... it is more expensive than what you can currently afford.  You should sell the car and " +
      "buy something that is more practical. "  },
  {topic: "loans", condition: "carLoanTooMuch", text: "You are spending more for your car each month than " +
      "I would recommend.  It might be a good idea to see if you can sell your car and find something that " +
      "is not so expensive."  },
  {topic: "loans", condition: "default", text: "I've reviewed your outstanding loans and I don't see " +
      "anything that I can recommend at this time.  "  },
  {topic: "general", condition: "savingForHome", text: "If you are saving money in your cash account for a " +
      "downpayment on a home, you are definitely doing the right thing....However...." 
  },
  {topic: "general", condition: "goodJobSavings", text: "You are doing a great job of saving money " +
    "each month.  It looks like you have enough left over for unexpected amounts and for extra things " +
    "that you may want to purchase."  },
  {topic: "general", condition: "increaseSavings", text: "You might want to increase the amount that " +
      "you are saving each month in your 401K account."  },
  {topic: "general", condition: "beginSavings", text: "You might want to consider starting a savings (401K)  " +
      "account."  },
  {topic: "general", condition: "startSavingYoung", text: "The sooner you begin saving money, the more money " +  
      "you will have put aside when you are ready to retire."  },
  {topic: "general", condition: "decreaseSavings", text: "OK, while I generally suggest that a person save as " +
      "much as they can, in your case, you may want to rethink this a bit.  You are saving so much that you " +
      "don't have a lot left over for unexpected expenses or perhaps a vacation of some kind."  },
  {topic: "general", condition: "creditCardMin", text: "I am noticing that you have been paying the " +
      "minimum amount each month on your credit card."
      },
  {topic: "general", condition: "creditCardMinOK", text: "It looks like the total outstanding balance is not too large. "  },
  {topic: "general", condition: "creditCardMinIssue", text: "You have a fairly large outstanding balance on your card " +
      "That will end up costing you a lot of extra money. "  },
  {topic: "general", condition: "ccManual", text: "I think you said you are paying your credit card manually  " +  
      "each month.  That is a really good idea because you can review your charges to ensure they are OK before " +
      "you pay. "},
  {topic: "general", condition: "ccManualOK", text: "Your outstanding credit card balance is pretty low so you must " +
      "be doing a good job of paying the balance each month."  },
  {topic: "general", condition: "ccManualIssue", text: "Your outstanding credit card balance is fairly large.  This " +
      "will be very expensive in the long run. " },
  {topic: "general", condition: "ccIntPenalty", text: "As you may know, you are being charged ||cclatefee|| on " +
      "the amount that you carry over from month to month."  },
  {topic: "general", condition: "incomeGreat", text: "You are doing a great job overall!  Your net income " +
      "is enough to allow you to cover unexpected expenses and still save money each month. " +
      "Bravo!!!"  }, 
  {topic: "general", condition: "incomeGood", text: "You are doing a good job of managing monthly expenses.  Your net income " +
      "is enough to allow you to cover monthly expenses.  Please consider taking action " +
      "on some of the suggestions I've made in the other areas." },
  {topic: "general", condition: "incomeOK", text: "Your overall status is OK.  Your net income may " +
      "be enough for day-to-day, but it is not really large enough to cover unexpected expenses or save money each month. " +
      "I hope you will follow through on some of the suggestions that I've made in other topics."  },
  {topic: "general", condition: "incomeNotOK", text: "You are currently in a pretty scary place in terms of your net income " +
      "It would be hard for you to pay for unexpected expenses and you really can't save money " +
      "right now.  Please consider doing some of the things that I suggested in the other topics. "  },   
  {topic: "general", condition: "incomeNegative", text: "You are currently spending more each month than you are making. " +
      "This will be a problem for you in the long run.  Please review the things that I suggested in the other topics " +
      "and do whatever you can to make more money or reduce your expense and loan amounts."  },    
  {topic: "general", condition: "default", text: "You know, I don't really have anything to advise other than " +
      "what you can find in the other topics." },
  {topic: "general", condition: "tooManyPurchases", text: "You told me about the 'cool things' that you have " +
      "purchased.  While I am sure these are fun, you have actually spent ||totalCost|| which is more than " +
      "the current amount of cash that you have.  I would suggest you think hard before buying any more of these " +
      "high-cost items." },
  {topic: "general", condition: "watchPurchases", text: "You have given me a list of the 'cool things' that you " +
      "purchased.  I'm not sure if you realize that the total amount of these special purchases is ||totalCost||. "  +
      "Although it seems you can afford these items, it might be better, in the future, to save the money instead."
   }
]

export default financialAdvisorText;