import {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import WaitTimeSpinner from '../shared-components/wait_time_spinner';
import buildUrl from '../../objects/CommonUse/build_URL';
import { setHeadersForFetch } from '../../objects/CommonUse/set_headers_for_fetch';
import Config from '../../config';


class UpdateEventMoodPoints extends Component {
  constructor(props) {
    super(props);
    this.state={
      eventsLoaded: false,
      reDisplay: false,
      headers: setHeadersForFetch(),
      environment: Config.dataURL.currentEnv,
    }   
      this.datasetSorted = false;
      this.processUpdateDataset();
}

processUpdateDataset(){
    this.setState({reDisplay: false});
    this.nbrUpdated = 0; 
    this.nbrMatched = 0;
    if (this.datasetSorted === false){
        this.sortedUpdateDataset = 
                this.props.updateDataset.sort((a, b) => (a.eventID > b.eventID) ? 1 : -1);
        this.datasetSorted = true;
    }
    for (let i=0; i<this.sortedUpdateDataset.length; i++){
        let eventUpdate = this.sortedUpdateDataset[i];
            if (eventUpdate.EventID !== this.previousEventId){
                if (this.previousEventId !== undefined &&
                    this.moodPointsChanged === true ){
                        this.nbrUpdated += 1;
                        console.log("nbr updated: ", this.nbrUpdated);
                        this.updateEvent();
                }
                this.previousEventId = eventUpdate.EventID;
                this.moodPointsChanged = false;
            }
            if (eventUpdate.MoodPointsChange != undefined){
                this.gameEvent = this.props.eventList.find(elem => elem._id === eventUpdate.EventID);
                if (this.gameEvent === undefined){
                    console.log("unable to find event in this.props.eventList: ", eventUpdate.EventID);
                }else{
                    this.changeMoodPoints(eventUpdate);
                }
            }
    }
    if (this.moodPointsChanged === true){
        this.nbrUpdated += 1;
        console.log("nbr updated: ", this.nbrUpdated);
        this.updateEvent();
    }
    //  the following will cause a re-render
    this.setState({reDisplay: true});
}

changeMoodPoints(eventUpdate){
    for (let i=0; i<this.gameEvent.responses.length; i++){
        let response = this.gameEvent.responses[i];
        if (response.btnText === eventUpdate.BtnText){
          console.log("btnText is equal")
          for (let j=0; j<response.possibleEffects.length; j++){
              let possibleEffect = response.possibleEffects[j];
              if (possibleEffect.closureText === eventUpdate.ClosureText &&
                 ((eventUpdate.TriggerCondition === undefined && possibleEffect.triggerCondition === "") ||
                   possibleEffect.triggerCondition === eventUpdate.TriggerCondition)){
                  this.nbrMatched += 1;
                  if (possibleEffect.moodPoints !== eventUpdate.MoodPointsChange){
                        possibleEffect.moodPoints = eventUpdate.MoodPointsChange;
                        this.moodPointsChanged = true;
                        
                  }
                  break;
              }
          }
        }
    }
}

render() {
   
      
return <>
    <Modal className="text-center" show={this.props.openModal}  onHide={() => this.props.onClose()} 
       size="med" animation={false}>
        <Modal.Header className="backgroundTopBottom" closeButton>
            <Modal.Title>Update Mood Points in Events</Modal.Title>
        </Modal.Header>
        <Modal.Body>
                {this.state.reDisplay ?
                    <h4 className="text-center mt-4">Processing Complete</h4>
                :
                    <h4 className="text-center mt-4">Process is Running</h4>
                }
               
                <table className="text-left mt-2">
                    <tbody>
                        <tr>
                            <td className="col-sm-6 font-weight-bold">Nbr of Rcds On Update Dataset</td>
                            <td className="col-sm-4">{this.props.updateDataset.length}</td>
                        </tr>
                        <tr>
                            <td className="col-sm-6 font-weight-bold">EventCategory Updated</td>
                            <td className="col-sm-4">{this.props.updateDataset[0].EventCategory}</td>
                        </tr>
                        <tr>
                            <td className="col-sm-6 font-weight-bold">Nbr of Records Matched</td>
                            <td className="col-sm-4">{this.nbrMatched}</td>
                        </tr>
                        <tr>
                            <td className="col-sm-6 font-weight-bold">Nbr of Events Updated</td>
                            <td className="col-sm-4">{this.nbrUpdated}</td>
                        </tr>
                    </tbody>
                </table>
          
        </Modal.Body>
        <Modal.Footer className="backgroundTopBottom p-0" >
             
        </Modal.Footer>
    </Modal>
    
  </>
  }
    
updateEvent(){
    // Update responses in events collection
   
    this.bodyObj={eventID: this.gameEvent._id,  responses: this.gameEvent.responses };
    const funcName = "updateEventResponse";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
          {method:'PUT', headers: this.state.headers,
          body: JSON.stringify(this.bodyObj)})
          .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        console.log('gameEvent mood points has been updated: ', this.gameEvent.title);
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message, errorObject: this.bodyObj};
                        this.props.processError(errorObj);
                    }
                });
            };
        })
    }
}

export default UpdateEventMoodPoints;